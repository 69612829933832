.App {
	background-color: white;
}
.header {
	border: 1px solid grey;
	height: 60px;
}

.header .col {
	text-align: center;
}

.search-section {
	height: 100px;
	width: 800px;
}

.body {
	border: 1px solid grey;
	border-radius: 2em;
}